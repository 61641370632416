.downloadContainer {
  position: relative;
}
.tableContainter {
  box-shadow: 0px 2px 4px -1px rgba(61, 61, 61, 0.6);
}
.dialog {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  top: 40px;
  right: 20px;
  position: absolute;
  z-index: 10;
}
.dialog > button {
  display: block;
  text-align: left;
  width: 100%;
}
.actionRow {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
}

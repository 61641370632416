.headerWrap {
  margin: 0 2rem 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.profileContainer {
  justify-content: space-between;
  position: relative;
  width: 100%;
  display: flex;
  padding: 1rem 0;
}
.avatarContainer {
  cursor: pointer;
  display: flex;
}
.avatar {
  border-radius: 50%;
}
.avatar.default {
  background-color: #e0e0e0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userName {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.7rem;
  min-width: 5rem;
  max-width: 10rem;
  border: 1px solid #3d3d3d10;
  border-radius: 30px;
  color: #3d3d3d;
}
.userName:hover {
  background-color: #3d3d3d10;
}
.userName span {
  padding-right: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.actionContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
.orgContainer {
  border-bottom: 1px solid #e2e2e2;
}
.orgContainer button {
  justify-content: flex-start;
  text-transform: none;
  width: 100%;
}
.orgContainer button > .avatar,
.orgContainer button .iconWhite {
  height: 24px;
  width: 24px;
  margin-right: 8px;
}
.orgContainer button > svg {
  font-size: 0.8rem;
  margin-left: 8px;
}
.iconWhite {
  align-items: center;
  background-color: #3d3d3d;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 36px;
  width: 36px;
}
.iconWhite svg {
  padding: 10px;
}
.templateContainer {
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  margin-top: 4px;
  position: absolute;
  white-space: nowrap;
}
.templateContainer button {
  display: flex;
  justify-content: flex-start;
  text-transform: capitalize;
  width: 100%;
}
.templateContainer button > svg {
  font-size: 0.8rem;
  margin-right: 4px;
}

.addData,
.addProject {
  display: flex;
  flex-grow: 1;
  padding: 1.5rem;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background-color: #3d3d3d10;
  min-height: 120px;
}

.addData p,
.addProject p {
  justify-content: center;
  align-items: center;
}

.crawlerInput {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  gap: 1rem;
}

.deleteIcon {
  cursor: pointer;
  visibility: hidden;
}

.responsiveListCard {
  display: flex;
  flex: 1 0 calc(33.3% - 0.66rem);
  max-width: calc(33.3% - 0.66rem);

  @media (max-width: 1440px) {
    flex: 1 0 calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
  }
  @media (max-width: 1024px) {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

.drawer {
  width: 700px;
  background-color: #fcfcfc;
  height: 100vh;
  position: relative;
}
.title {
  display: flex;
  align-items: center;
  box-shadow: rgba(61, 61, 61, 0.6) 0px 2px 4px -1px;
  gap: 1.5rem;
  padding: 1.25rem 1.5rem;
  position: relative;
  z-index: 1;
}

.title > p {
  font-size: 1.25rem;
  font-weight: 700;
}
.main {
  box-sizing: border-box;
  height: calc(100% - 90px);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  padding: 1.5rem 2rem;
}
.main > div {
  background-color: #F6F6F6;
  border-radius: 4px;
  padding: 1rem 1.25rem;
}
.main > div h4 {
  margin: 0;
}
.main > div .inputContainer > label {
  color: #3d3d3d;
  display: block;
  font-size: 0.8rem;
  font-weight: 700;
  margin: 1rem 0 .5rem;
}
.main div.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main div.flex > div:nth-child(2) {
  display: flex;
  justify-content: flex-end;
  width: 55%;
}
button.add {
  background-color: white;
  border: 1px dashed #3d3d3d80;
  text-transform: none;
  width: 100%;
}
.variableContainer {
  display: flex;
}
.variableContainer > span:first-child {
  display: flex;
  flex: 0 0 142px;
  font-size: 0.8rem;
  gap: 0.3rem;
  height: 32px;
  padding-right: 12px;
}
.hintText {
  display: block;
  margin: 0.75rem;
  font-size: 0.7rem;
}
.sourceTable > div:first-child {
  display: flex;
  justify-content: space-between;
}
.toolInput {
  color: #3d3d3d;
  font-size: 0.8rem;
}
.toolInput > h5 {
  margin-top: 1rem;
}
.codeContainer {
  background-color: #3d3d3d;
  box-sizing: border-box;
  color: #76d9e6;
  font-family: Noto Sans Mono, "Courier New", Courier, monospace;
  height: 470px;
  padding: 20px 28px;
  width: 100%;
}
.requestContainer > h4 {
  padding-bottom: 1rem;
}
.requestContainer .column {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.75rem;
}
.requestContainer .column h5 {
  margin-top: 0;
}
.requestContainer .column > div:nth-child(1) {
  flex: 0 0 35%;
}
.requestContainer .column > div:nth-child(2) {
  flex: 1 0;
  overflow: hidden;
}
.requestContainer .column > div:nth-child(2) > div {
  display: flex;
}

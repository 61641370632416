.accountContainer .memberTableLike {
  display: grid;
  grid-template-columns: auto 36% 100px 160px;
  flex-direction: column;
  font-size: 0.8rem;
  gap: 16px;
  padding: 0.5rem 0;
}
.accountContainer .workspaceTableLike {
  display: grid;
  grid-template-columns: auto 140px 40px;
  flex-direction: column;
  font-size: 0.8rem;
  gap: 1rem;
}
.accountContainer .memberTableLike > div,
.accountContainer .workspaceTableLike > div {
  display: flex;
  align-items: center;
}
.accountContainer .loadingCard,
.accountContainer .tabPanelCard {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 2px 4px -1px rgba(61, 61, 61, 0.6);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 42rem;
  padding: 2rem;
}
.accountContainer .tabPanelCard + .tabPanelCard {
  margin-top: 2rem;
}
.accountContainer .tabPanelCard h4 {
  margin: 1rem 0 0;
}
.accountContainer .usageContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
}
.accountContainer .usageItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.actionDialog {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 32px;
  right: 20px;
  width: 10rem;
  z-index: 1;
}

.driver-popover.driverjs-theme {
  background-color: #fff;
  color: #3d3d3d;
  box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.1);
}

.driver-popover.driverjs-theme .driver-popover-description {
  color: #3d3d3d;
  font-size: 0.9rem;
}

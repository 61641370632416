.loadingMask {
  background-color: #fcfcfc;
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 1;
}

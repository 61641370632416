body {
  background-color: #fcfcfc;
  overflow-y: hidden;
}

.portal-container {
  padding: 2rem;
}

.portal-wrap {
  display: flex;
  gap: 2rem;
  margin: auto;
  max-width: 70rem;
  height: 80vh;
}

.portal-wrap > div {
  flex: 1;
}

.login-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20rem;
  padding: 2rem;
  border-radius: 10px;
  position: relative;
}

.login-logo {
  width: 8rem;
  margin-bottom: 2rem;
}

.signup-title {
  display: flex;
  justify-content: center;
}

.login-title {
  margin-bottom: 1.2rem;
  display: flex;
  justify-content: center;
}

.signup-title p,
.login-title p {
  font-size: 0.7rem;
  z-index: 2;
  background-color: #ffffff;
  padding: 0 0.5rem;
}

.signup-title hr,
.login-title hr {
  width: 70%;
  border-top: 1px solid #3d3d3d30;
  position: absolute;
}

button img {
  width: 1rem;
  margin-right: 0.2rem;
}

.sso-wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sso-wrap.signup {
  margin-bottom: 1.5rem;
}

.sso-wrap.login {
  margin-bottom: 0.5rem;
}

.portal-decor-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
}

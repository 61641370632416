.customNode {
  background-color: #fff;
  border: 1px solid #3d3d3d30;
  border-radius: 0 4px 4px;
  padding: 0.75rem;
  width: 25rem;
  cursor: pointer;
  transition: all 200ms;
}
.customNode:hover {
  box-shadow: 0px 2px 4px -1px rgba(61, 61, 61, 0.6);
}
.customNode .icon {
  border-radius: 4px;
  border: 1px solid #3d3d3d30;
  height: 40px;
  width: 40px;
  padding: 8px;
}
.customNode .title {
  display: flex;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: 400;
}
.customNode .description {
  font-size: 1rem;
  margin-top: 0.5rem;
}
.customNode .actionContainer {
  display: flex;
  align-items: center;
  position: relative;
}
.customNode .actionContainer .dialog {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  position: absolute;
  left: 32px;
  top: 26px;
  width: 10rem;
}
.customNode .tab {
  background-color: #3d3d3d;
  border-radius: 4px 4px 0 0;
  color: #fff;
  font-size: 0.8rem;
  left: 0;
  text-align: center;
  top: -23px;
  padding: .25rem;
  position: absolute;
  width: 120px;
}

.tagify__dropdown {
  border-top: 0;
}

.tagify__dropdown[position="text"] {
  font-size: 0.8rem;
  box-shadow: 0 0 10px #00000026 !important;
}

.tagify__dropdown[position="text"] .tagify__dropdown__wrapper {
  border-width: 0;
}

.tagify__dropdown__wrapper {
  border: none;
}

.tagify__dropdown__item {
  padding: 0.5rem;
  border-radius: 0;
}

.tagify__dropdown__item--active {
  color: #3d3d3d;
  background: #3d3d3d30;
}

.mixedTextarea .tagify__tag:focus div::before,
.mixedTextarea .tagify__tag:hover:not([readonly]) div::before {
  --tag-bg-inset: -0px;
  --tag-bg: var(--tag-hover);
}

.mixedTextarea .tagify__tag__removeBtn::after {
  content: "×";
  transition: 0.1s, color 0s;
}

.mixedTextarea .tagify__tag__removeBtn:hover {
  color: #fff;
  background: #3d3d3d80;
}

.mixedTextarea .tagify__tag__removeBtn:hover + div > span {
  opacity: 1;
}

.mixedTextarea .tagify__tag__removeBtn:hover + div::before {
  box-shadow: 0 0 0 0 !important;
  /* transition: box-shadow .2s */
}

.mixedTextarea .tagify__tag > div {
  border-radius: 25px;
  padding: 2.5px 5px 2.5px 8px;
  display: flex;
}
.mixedTextarea .tagify__tag > div::before {
  background-color: #fff;
  box-shadow: 0 0 0 #fff;
  border: 1px solid #3d3d3d;
}
.mixedTextarea .Mui-disabled .tagify__tag > div::before {
  opacity: 0.5;
}
.mixedTextarea .tagify__tag > div > span {
  color: #3d3d3d;
  font-size: 0.6rem;
}
.mixedTextarea .tagify__tag.tagify--notAllowed > div::before {
  border: 1px solid #f44336;
}
.mixedTextarea .tagify__tag.tagify--notAllowed > div > span {
  color: #f44336;
  opacity: 1;
}
.mixedTextarea .tagify__tag.tagify--notAllowed .tagify__tag__removeBtn {
  color: #f44336;
}
.mixedTextarea .tagify--mix > .tagify__input {
  padding: 0;
  margin: auto;
  display: inline-block;
  line-height: 1.3;
}
.mixedTextarea.no-wrap .tagify--mix::-webkit-scrollbar {
  display: none;
}
.mixedTextarea.no-wrap .tagify--mix > .tagify__input {
  white-space: nowrap;
}
.mixedTextarea .tagify--mix {
  border: none;
  height: 18.3px;
  min-height: 18.3px;
  overflow-y: hidden;
}
.mixedTextarea.row-6 .tagify--mix {
  height: auto;
  min-height: 130px;
}
.mixedTextarea.row-15 .tagify--mix {
  height: auto;
  min-height: 300px;
}

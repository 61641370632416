.actionArea {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
}
.actionCell button {
  padding: 0.25rem;
}
.actionCell button > svg {
  font-size: 1rem;
}
td.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.logContainer {
  box-sizing: border-box;
  height: calc(100% - 90px);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow-y: auto;
  padding: 1.5rem 2rem;
}

.logHead {
  font-size: 0.8rem;
  display: flex;
  gap: 0.3rem;
}
.logHead h5 {
  display: inline-block;
  margin: 0;
}
.logItem,
.logItem .output {
  padding: 1rem;
  border: 1px solid #3d3d3d80;
  border-radius: 4px;
  position: relative;
}
.logItem h4 {
  margin: 0;
}
.logItem h5 {
  margin: 0.5rem 0 0.25rem;
  color: #3d3d3d;
}
.logItem h5 > span {
  font-weight: 400;
}
.logItem:after {
  content: "";
  position: absolute;
  bottom: -1.6rem;
  left: 3rem;
  height: 1.6rem;
  border-left: 1px solid #3d3d3d80;
}
.logItem.output:after {
  border: none;
}
.logTitle {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 1.25rem;
}
.logContent {
  background-color: #3d3d3d10;
  font-size: 0.8rem;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  line-height: 1.25rem;
  position: relative;
}
.logContent.collapse, .logContent.expand {
  padding-bottom: 36px;
}
.logContent.collapse .content {
  display: -webkit-box;
  line-clamp: 5;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.logContent.collapse .content:before {
  content: '';
  bottom: 36px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(0deg, rgb(240, 240, 240, 0.8) 0px, rgba(240, 240, 240, 0) 60px);
}
button.more {
  bottom: 0;
  font-weight: 700;
  left: 0;
  position: absolute;
  text-transform: capitalize;
  width: 100%;
}
.dataItem {
  display: block;
  background-color: #3d3d3d;
  padding: 1rem;
  border-radius: 4px;
  color: #76d9e6;
  font-family:
    Noto Sans Mono,
    "Courier New",
    Courier,
    monospace;
}
.dataItem:not(:last-of-type) {
  margin-bottom: 0.5rem;
}

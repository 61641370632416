* {
  font-family:
    Inter,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica,
    Arial,
    sans-serif;
}

body,
h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
  color: #3d3d3d;
  word-wrap: break-word;
}

h1,
h2 {
  font-size: 1.8rem;
  margin-bottom: 0.8rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  color: #3d3d3d;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

h5 {
  font-weight: 700;
  padding: 0;
  margin-top: 0.8rem;
  margin-bottom: 0.5rem;
  text-align: left;
}

p,
a,
li,
h5 {
  font-size: 0.8rem;
}

a {
  color: #3d3d3d;
}

a.link {
  color: #3d3d3d;
  text-decoration: none;
}

a.link.disabled {
  color: #3d3d3d80;
  cursor: not-allowed;
}

.tooltipLink {
  color: #ffffff;
}

p {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.cardTitle {
  display: flex;
  align-items: center;
}

.cardTitle h5 {
  margin: 0 0 0 0.4rem;
}

.optional {
  font-style: italic;
  color: #3d3d3d80;
  font-weight: 500;
  font-size: 0.9rem;
}

.allFrame {
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100vh;
}

.navFrame {
  min-width: 15rem;
  background-color: #f5f8fb;
}

.mainFrame {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  border-radius: 10px 0 0 10px;
  box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.1);
  background-color: #fcfcfc;
}

.container {
  margin: 0 2rem 2rem 2rem;
}

.footer {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem;
}

.footer p {
  font-size: 0.8rem;
}

.not-found {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.not-found .button {
  margin-top: 1rem;
}

.option {
  position: absolute;
  width: 10rem;
  z-index: 10;
  top: 70%;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.option a {
  text-decoration: none;
  display: block;
  padding: 0.8rem;
  font-size: 0.8rem;
  cursor: pointer;
}

.option a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

select {
  display: block;
  padding: 0.5rem;
  border: 1px solid #3d3d3d80;
  border-radius: 6px;
}

option {
  text-indent: 20px;
}

input {
  border: 1px solid #3d3d3d80;
}

.textarea:hover {
  outline: none;
  border: 1px solid #3d3d3d !important;
}

.textarea:focus {
  outline: none;
  border: 1px solid #3d3d3d !important;
}

/* TBC */

.MuiBackdrop-root {
  margin-top: 0 !important;
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-state img {
  width: 20rem;
}

.hoverIcon {
  transition: background-color 0.3s ease;
  border-radius: 50%;
  padding: 10px;
}

.hoverIcon:hover {
  background-color: #9e9e9e33;
  border-radius: 50%;
  padding: 10px;
}

.react-flow__attribution a {
  display: none;
}

textarea.default {
  box-sizing: border-box;
  border: 1px solid #3d3d3d80;
  border-radius: 6px;
  padding: 0.5rem;
  resize: none;
  width: 100%;
  height: 15rem;
}

.text-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-disabled {
  pointer-events: none;
  cursor: default;
}
.cardTitle {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 8px;
}
.cardDescription {
  font-size: 1rem;
  margin-bottom: 32px;
}
.cardFooter {
  display: flex;
  height: 60px;
  font-size: 0.8rem;
  justify-content: flex-end;
}
.hideListHint {
  align-items: center;
  display: flex;
  font-size: 0.7rem;
  gap: 4px;
}
ul.checkList {
  list-style-type: none;
  padding-left: 0;
}
li.checkListItem {
  align-items: center;
  display: flex;
  font-size: 1rem;
  gap: 12px;
  justify-content: space-between;
  margin-bottom: 16px;
  opacity: 0.2;
}
li.checkListItem.active {
  opacity: 1;
  padding: 8px 0;
}
.checkListText {
  align-items: center;
  display: flex;
  font-weight: 500;
  gap: 12px;
}

.dashContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
}
.usageCard {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 550px;
  padding: 1.5rem;
}
.dashStat {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}
.dashStat p:last-of-type {
  font-style: italic;
  font-size: 0.7rem;
}
.resourcesContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
}

.comingsoonAppSection {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.availableAppSection {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.availableApp,
.comingsoonApp {
  border: 1px solid #3d3d3d30;
  padding: 0.8rem;
  border-radius: 4px;
  min-width: 10rem;
  max-width: 10rem;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #ffffff;
}

.availableApp {
  cursor: pointer;
}

.availableApp:hover {
  box-shadow: 0px 2px 4px -1px rgba(61, 61, 61, 0.6);
}

.comingsoonApp {
  position: relative;
  cursor: default;
}

.comingsoonApp::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #3d3d3d80;
  left: 0;
  top: 0;
  border-radius: 4px;
}

.chat-window {
  height: calc(100vh - 8rem);
  display: flex;
  flex-direction: column;
  /* background-color: #fff; */
  /* margin-top: 5rem; */
  /* border: 1px solid #3d3d3d80; */
  border-radius: 10px;
  /* box-shadow: 0px 2px 4px -1px rgba(61,61,61,0.6); */
  /* overflow-y: auto; */
}

.chat-window h3 {
  padding-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.chat-window h4 {
  margin: 1.3rem 0 0.6rem 0;
}

.chat-window h5 {
  margin: 0 0 0 0.4rem;
}

.message-text h1, 
.message-text h2,
.message-text h3,
.message-text h4 {
  padding: 0rem;
  margin: 0rem;
}

.chat-window .title {
  display: flex;
  align-items: center;
  padding: 1.5rem;
}

.chat-window .file-container {
  display: inline-flex;
  gap: 8px;
}

.chat-window .file-card {
  background-color: #fff;
  border: 1px #ccc solid;
  border-radius: 4px;
  cursor: default;
  display: flex;
  font-size: 0.5rem;
  margin-bottom: 8px;
  max-width: 160px;
  padding: 0.5rem;
  position: relative;
}

.chat-window .file-card:hover {
  background-color: rgba(61, 61, 61, 0.04);
}

.chat-window .file-card .icon {
  align-items: center;
  background-color: #3d3d3d;
  border-radius: 4px;
  color: #fff;
  display: flex;
  flex: 0 0 12.8px;
  justify-content: center;
  padding: 4px;
  margin-right: 8px;
}

.chat-window .file-card > div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chat-window .file-card > div > div {
  text-overflow: ellipsis;
}

.chat-window .file-card .btn-close {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  padding: 2px;
  position: absolute;
  top: -8.4px;
  right: -8.4px;
  z-index: 1;
}

.chat-window .file-card .btn-close > svg {
  font-size: 0.8rem;
}

.chat-window .file-card .icon > svg {
  font-size: 0.8rem;
  transform: rotate(90deg);
}

.messages {
  max-height: 100%;
  flex-grow: 1;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.messages::-webkit-scrollbar {
  display: none;
}

.chatView {
  align-self: flex-start;
  color: #3d3d3d;
  display: flex;
  flex-direction: column;
  width: 100%;
  word-break: break-all;
}

.apiView {
  color: #3d3d3d;
  flex-direction: column;
  width: 100%;
  display: inline-block;
  text-align: left;
  width: 100%;
}

.message.sent::before {
  content: "Input";
  font-size: 0.8rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.message.received::before {
  content: "Output";
  font-size: 0.8rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.message-with-loader {
  align-items: center;
}

/* .message.received .message-text {
  display: inline-block;
  text-align: left;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 4px;
} */

.message-text {
  display: inline-block;
  text-align: left;
  width: 100%;
}

.input-container {
  display: flex;
  align-items: baseline;
  padding: 1rem;
  background-color: #ffffff;
  gap: 0.5rem;
  border-radius: 10px;
}

input[type="text"].test-input {
  border: 1px solid #3d3d3d80;
  border-radius: 6px;
  padding: 0.65rem 0.6rem;
  font-size: 0.8rem;
  width: 100%;
}

input[type="text"].test-input:disabled {
  cursor: not-allowed;
}

input[type="text"].test-input:focus {
  outline: none;
  border: 1px solid #3d3d3d;
}

#text-container.blink::after {
  content: "";
  border-right: 0.8em solid #3d3d3d;
  animation: blink 0.45s infinite;
}

.blink-effect {
  content: "";
  border-right: 0.1em solid #3d3d3d;
  animation: blink 0.45s infinite;
}

@keyframes blink {
  10% {
    opacity: 0;
  }
}

#text-container * {
  font-size: 0.9rem;
}

#text-container {
  max-width: 100%;
  width: 100%;
  box-sizing: content-box;
  word-break: normal;
  word-wrap: break-word;
  display: flex;
  align-items: center;
}

#text-container table {
  border-collapse: collapse;
  border: 1px solid #3d3d3d80;
  margin: 1rem 0;
  width: 100%;
}

#text-container td,
#text-container th,
#text-container tr {
  border-collapse: collapse;
  border: 1px solid #3d3d3d80;
  padding: 0.5rem;
}

#text-container table table {
  border: none;
}

#text-container table table tr,
#text-container table table td,
#text-container table table th {
  border: none;
}

#text-container p {
  display: block;
  font-size: 0.9rem;
}

#text-container pre {
  margin: 0;
}

#text-container code {
  max-width: 100%;
  white-space: pre-wrap;
}

.citation-head {
  font-size: 0.7rem!important;
  font-weight: 700;
  margin-top: 0.5rem;
}

.citation-content {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
}

.citation-item {
  display: block;
  font-size: 0.7rem!important;
  background-color: #3d3d3d80;
  color: #fff;
  padding: 0.1rem 0.5rem;
  border-radius: 30px;
  line-height: 2;
  flex-shrink: 0;
  white-space: nowrap;
}

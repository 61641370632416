.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.drawer {
  width: 700px;
  background-color: #fcfcfc;
  height: 100vh;
}
.drawer .main {
  box-sizing: border-box;
  height: calc(100% - 90px);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  padding: 1.5rem 2rem;
}
.drawer .title {
  display: flex;
  align-items: center;
  box-shadow: rgba(61, 61, 61, 0.6) 0px 2px 4px -1px;
  gap: 1.5rem;
  padding: 1.25rem 1.5rem;
  position: relative;
  z-index: 1;
  justify-content: space-between;
}
.drawer .title > p {
  font-size: 1.25rem;
  font-weight: 700;
}
.editableArea {
  border: 1px solid #3d3d3d30;
  border-radius: 4px;
}
.editableArea .header {
  align-items: center;
  border-bottom: 1px solid #3d3d3d30;
  display: flex;
  gap: 4rem;
  justify-content: space-between;
  padding: 16px 12px;
}
.editableArea .header > div:first-child {
  display: flex;
  align-items: center;
}
.editableArea .header .fieldContainer {
  flex: 1;
  margin-left: 4px;
  word-break: break-word;
}
.actionArea {
  display: flex;
  gap: 0.5rem;
  position: relative;
}
.actionArea .versionText {
  align-items: center;
  color: #3d3d3d;
  display: flex;
  font-size: 0.8rem;
  margin-right: .75rem;
  text-decoration: underline;
}
.actionArea .moreOptions {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  padding: 0.5rem 0;
  position: absolute;
  right: 1rem;
  top: 2rem;
  width: 10rem;
  z-index: 11;
}
.actionArea .moreOptions button {
  display: block;
  padding: 0.5rem 1rem;
  line-height: 1;
  text-align: left;
  text-transform: capitalize;
  width: 100%;
}

.morePgOptions {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  position: absolute;
  right: 2.5rem;
  top: 3.5rem;
  width: 10rem;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  font-size: 0.8rem;
}

.drawer {
  width: 600px;
  background-color: #fcfcfc;
  height: 100vh;
  position: relative;
}
.main {
  box-sizing: border-box;
  height: calc(100% - 90px);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  padding: 1.5rem 2rem;
}
.main > div {
  background-color: #F6F6F6;
  border-radius: 4px;
  padding: 1rem 1.25rem;
}
.main > div h4 {
  margin: 0;
}
.main div.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main div.flex > div:nth-child(2) {
  display: flex;
  justify-content: flex-end;
  width: 55%;
}

.navbar {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
}

.nav-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  width: 100%;
  box-sizing: border-box;
}

.logo {
  width: 6rem;
}

.navbar-links {
  width: 95%;
}

.navbar-links a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem;
  position: relative;
  border-radius: 0 10px 10px 0;
  transition: all 0.1s;
  text-decoration: none;
}

.navbar-links a:hover {
  background-color: #3d3d3d30;
}

.nav-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0rem 1rem;
  box-sizing: border-box;
}

.navFooter {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem;
}

.navFooter a {
  font-size: 0.7rem;
}

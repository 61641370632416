.apiContainer {
  display: flex;
  flex-direction: column;
}

.apiOption {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 12rem;
}

.apiOptionItem {
  display: flex;
  flex-direction: column;
}

.apiAction {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
}

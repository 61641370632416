.dataSourceItem > * {
  display: block;
}
.vextSourceResult {
  box-sizing: border-box;
  border: 1px solid #3d3d3d80;
  border-radius: 6px;
  padding: 0.5rem;
  resize: none;
  max-width: 50rem;
  width: 100%;
  font-size: 0.8rem;
  box-sizing: border-box;
  height: 30rem;
  position: relative;
  margin-top: 1rem;
}
.vextSourceResult:focus {
  outline: none;
  border: 1px solid #3d3d3d;
  font-size: 0.8rem;
}

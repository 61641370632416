.itemsContainer {
  border-radius: 10px;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px -1px rgba(61, 61, 61, 0.6);
  position: relative;
  width: 100%;
}

.itemsContainer:hover {
  cursor: pointer;
  background-color: #f5f7f9;
}

.loadingContainer {
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
}

.itemsContainer .appInfo,
.itemsContainer .dataInfo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.itemsContainer .appAction {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* .itemsContainer:not(:last-of-type) {
  margin-bottom: 1.5rem;
} */

.dataSourceImg {
  width: 1.2rem;
  margin-right: 0.5rem;
  transform: translateY(0.3rem);
}

.appDetailsContainer {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: flex-start;
  flex-wrap: wrap;
}

.appDetailsContainer > div:first-child,
.appDetailsContainer > div:last-child {
  /* width: 45%; */
  flex: 1;
}

.appDetailsContainer > div:first-child {
  min-width: 50%;
}

.appConfig {
  cursor: pointer;
}

.appConfig.outlined {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #3d3d3d30;
}

.appConfig:hover {
  background-color: #f5f7f9;
  border-radius: 4px;
}

.appDate {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  gap: 0.5rem;
}

.appDate > i {
  font-size: 0.7rem;
}

.appQuickAction {
  border: 1px solid #3d3d3d30;
  padding: 1.5rem 1rem;
  border-radius: 10px;
  min-width: 10rem;
  cursor: pointer;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #ffffff;
}

.appQuickAction:hover {
  box-shadow: 0px 2px 4px -1px rgba(61, 61, 61, 0.6);
}

.appDirectoryItem {
  /* border: 1px solid #3d3d3d59; */
  border-radius: 10px;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px -1px rgba(61, 61, 61, 0.6);
  position: relative;
  max-width: 40rem;
}
